.KN-grid {
    display: flex;
    margin-top: 1rem;
    gap: 1.25rem;
}

.KN-container {
    margin-top: 3rem;
    display: flex;
    gap: 1rem;
}

.KN-container img {
    width: 10rem;
    height: 12rem;
    border-radius: 8px;
}

.KN-text {
    width: 50%;
}

@media only screen and (max-width: 480px) {

    .KN-grid {
        flex-direction: column;
        gap: 0;
    }

    .KN-container img {
        width: 9rem;
        height: 10rem;
    }
}